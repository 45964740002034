<svelte:options tag="right-side-window" />

<script>
  import { createEventDispatcher } from "svelte";
  // import { DOMAIN } from "../../config/api-variables";
  import {
    DeviceType,
    OrganisationDetails,
    userDetails,
  } from "../../../../stores/authStores";
  import OffersTab from "./components/OffersTab.svelte";
  import { AppDefaults } from "../../../../config/global-enums";
  import { fly } from "svelte/transition";
  import { circOut } from "svelte/easing";
  import SettingsModal from "../SettingsModal.svelte";
  import ChatListing from "../../../ChatListing/ChatListing.svelte";
  import { DOMAIN } from "../../../../config/api-variables";
  import ProductListing from "../../../ProductListing/ProductListing.svelte";
  import CampaignListing from "../../../CampaignListing/CampaignListing.svelte";
  import StartNewConversationButton from "../../../../common/components/StartNewConversationButton.svelte";
  import ManageProfileDrawer from "../../../FullScreenWidget/components/ManageProfileDrawer.svelte";
  import { isSupportListVisible } from "../../../../stores/chatStores";
  import ListingMenuSection from "../../../FullScreenWidget/components/ListingMenuSection.svelte";
  import { selectedListingMenuItem } from "../../../../stores/menuStores";

  const dispatch = createEventDispatcher();

  export let access_by_type = "customer";
  export let selectedMessage;

  let currentTab = "Campaign";

  let drawerVisible = false;
  let hideDrawerBody = false;
  let showLanguageSettings = false;

  let profileDrawerVisible = false;

  $: if ($isSupportListVisible) {
    currentTab = "Support";
  } else {
    currentTab = $selectedListingMenuItem?.name || $OrganisationDetails?.title;
  }

  // function setActiveTab(tab) {
  //   currentTab = tab;
  // }

  // const tabItemList = [
  //   {
  //     title: "Campaign",
  //   },
  //   {
  //     title: "Products",
  //   },
  // ];

  const menuItems = [
    // {
    //   id: 1,
    //   label: "History",
    //   icon: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#000000}</style><path d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z"/></svg>`,
    //   action: () => {
    //     console.log("History");
    //   },
    // },
    {
      id: 2,
      label: "Language Settings",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#000000}</style><path d="M0 128C0 92.7 28.7 64 64 64H256h48 16H576c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H320 304 256 64c-35.3 0-64-28.7-64-64V128zm320 0V384H576V128H320zM178.3 175.9c-3.2-7.2-10.4-11.9-18.3-11.9s-15.1 4.7-18.3 11.9l-64 144c-4.5 10.1 .1 21.9 10.2 26.4s21.9-.1 26.4-10.2l8.9-20.1h73.6l8.9 20.1c4.5 10.1 16.3 14.6 26.4 10.2s14.6-16.3 10.2-26.4l-64-144zM160 233.2L179 276H141l19-42.8zM448 164c11 0 20 9 20 20v4h44 16c11 0 20 9 20 20s-9 20-20 20h-2l-1.6 4.5c-8.9 24.4-22.4 46.6-39.6 65.4c.9 .6 1.8 1.1 2.7 1.6l18.9 11.3c9.5 5.7 12.5 18 6.9 27.4s-18 12.5-27.4 6.9l-18.9-11.3c-4.5-2.7-8.8-5.5-13.1-8.5c-10.6 7.5-21.9 14-34 19.4l-3.6 1.6c-10.1 4.5-21.9-.1-26.4-10.2s.1-21.9 10.2-26.4l3.6-1.6c6.4-2.9 12.6-6.1 18.5-9.8l-12.2-12.2c-7.8-7.8-7.8-20.5 0-28.3s20.5-7.8 28.3 0l14.6 14.6 .5 .5c12.4-13.1 22.5-28.3 29.8-45H448 376c-11 0-20-9-20-20s9-20 20-20h52v-4c0-11 9-20 20-20z"/></svg>`,
      action: () => {
        hideDrawerBody = true;
        showLanguageSettings = true;
      },
    },
    // {
    //   id: 3,
    //   label: "Request to call",
    //   icon: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#000000}</style><path d="M280 0C408.1 0 512 103.9 512 232c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-101.6-82.4-184-184-184c-13.3 0-24-10.7-24-24s10.7-24 24-24zm8 192a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm-32-72c0-13.3 10.7-24 24-24c75.1 0 136 60.9 136 136c0 13.3-10.7 24-24 24s-24-10.7-24-24c0-48.6-39.4-88-88-88c-13.3 0-24-10.7-24-24zM117.5 1.4c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24z"/></svg>`,
    //   action: () => {
    //     console.log("Request to call");
    //   },
    // }
  ];

  const handleNewConverstionClick = () => {
    dispatch("startNewChat");
    selectedMessage = null;
    selectedItem = null;
  };
</script>

<div
  class="chat-list-container"
  style="width: {$DeviceType === 'desktop'
    ? '300px'
    : '100%'}; display: flex; flex-direction: column;"
>
  <div class="chat-header-container">
    <div
      class="chat-header"
      style="padding: 12px; display: flex; align-items: center; font-family: sans-serif"
    >
      <!-- <button
        on:click={() => {
          drawerVisible = !drawerVisible;
        }}
        style="border: none; background-color: transparent; outline: none; cursor: pointer; margin-right: 4px"
      >
        <svg
          class="icon-color"
          xmlns="http://www.w3.org/2000/svg"
          height="1.3em"
          viewBox="0 0 448 512"
          style="margin-right: 4px;"
          ><path
            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
          /></svg
        >
      </button> -->
      <div
        class="chat-header-avatar"
        style="display: flex; align-items: center;"
      >
        {#if $isSupportListVisible}
          <button
            style="border: none; display: flex; cursor: pointer; padding: 0 8px;"
            on:click={() => {
              isSupportListVisible.set(false);
            }}
          >
            <svg
              class="icon-color"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              ><path
                d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z"
              /></svg
            >
          </button>
        {/if}
        {#if $OrganisationDetails?.logo}
          <img
            src={DOMAIN + $OrganisationDetails?.logo}
            alt="Avatar"
            style="height: 28px; margin-right: 8px;"
          />
        {:else}
          <img
            src={DOMAIN + "/media/default/profile/person/default.png"}
            alt="Avatar"
            style="height: 28px; margin-right: 8px;"
          />
        {/if}
      </div>
      <div class="cleandesk-header-profile-name custom-text-color">
        {currentTab}
      </div>

      <div class="dropdown" style="margin-left: auto"></div>

      <button
        style="margin-right: 8px; display: flex;"
        on:click={() => {
          profileDrawerVisible = !profileDrawerVisible;
        }}
      >
        <img
          src={DOMAIN + $userDetails?.profile_image}
          alt="A"
          style="height: 28px; border-radius: 50%;"
        />
      </button>

      <button
        on:click={() => dispatch("chatListVisible")}
        style="background: none;
        border: none;
        align-items: center;
        display: flex;
        padding: 1px 8px 1px 2px;"
      >
        <svg
          class="icon-color"
          xmlns="http://www.w3.org/2000/svg"
          height="18px"
          viewBox="0 0 384 512"
          ><path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
          /></svg
        >
      </button>
    </div>
  </div>

  {#if $isSupportListVisible}
    <!-- <ChatListing
      {access_by_type}
      {selectedMessage}
      on:startNewChat={handleNewConverstionClick}
      on:selectedItem={(item) => dispatch("selectedItem", item.detail)}
    /> -->
    <ChatListing {access_by_type} />
  {:else}
    <div style="display: flex; justify-content: center; margin: 4px 8px;">
      <StartNewConversationButton
        expanded={true}
        extraStyles="margin: 0; width: 60%; padding: 4px;"
        textStyles="font-size: 14px;"
      />
    </div>

    <div
      class="cleandesk-test-change-later"
      style="flex: 1; background-color: #fafafa; overflow: hidden auto; overflow: hidden auto;"
    >
      <ListingMenuSection />
      <!-- <div
        class="tab-switch"
        style="display: flex; border-bottom: 0.5px solid #dcdfe4; background: #fff; height: 40px;"
      >
        {#each tabItemList as tabItem}
          <div
            style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin: 0px 8px; padding: 4px; cursor: pointer; background: #fff; {currentTab ===
            tabItem?.title
              ? 'border-bottom: 2px solid #000;'
              : ''}"
            on:click={() => setActiveTab(tabItem?.title)}
            on:keydown={() => setActiveTab(tabItem?.title)}
          >
            <p style="margin: 0; font-size: 16px">{tabItem?.title}</p>
          </div>
        {/each}
      </div>
      <div class="tab tab1" class:active={currentTab === "Products"}>
        <ProductListing />
      </div>
      <div
        class="tab tab2"
        class:active={currentTab === "Support"}
        style="height: 100%; overflow: hidden; background: white;"
      >
        <ChatListing
          {access_by_type}
          {selectedMessage}
          on:startNewChat={handleNewConverstionClick}
          on:selectedItem={(item) => dispatch("selectedItem", item.detail)}
        />
      </div>
      <div class="tab tab3" class:active={currentTab === "Campaign"}>
        <CampaignListing />
      </div> -->
    </div>
  {/if}
</div>

<section class="app">
  {#if drawerVisible}
    <div
      class="drawer {$DeviceType === 'desktop'
        ? 'drawer-desktop'
        : 'drawer-mobile'}"
      transition:fly={{ x: 0, easing: circOut }}
    >
      <div class="drawer-header" style="padding: 8px; display: flex;">
        <!-- {#if !hideDrawerBody} -->
        {#if hideDrawerBody}
          <button
            class=""
            on:click={() => (hideDrawerBody = !hideDrawerBody)}
            style="background: none; border: none;"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.2em"
              viewBox="0 0 448 512"
              ><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style
              >
                svg {
                  fill: #000000;
                }
              </style><path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
              /></svg
            >
          </button>
        {/if}
        <button
          class=""
          on:click={() => {
            drawerVisible = !drawerVisible;
            hideDrawerBody = false;
          }}
          style="background: none; border: none; margin-left: auto;"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18px"
            viewBox="0 0 384 512"
            ><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style
            >
              svg {
                fill: #000000;
              }
            </style><path
              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
            /></svg
          >
        </button>
      </div>

      <div class="drawer-body" style="padding: 8px;">
        {#if !hideDrawerBody}
          {#if access_by_type === "customer"}
            <div
              style="display: flex; justify-content: center; margin: 4px 8px;"
            >
              <button
                class="primary-background-color custom-text-color"
                style="    width: 60%;
              border: none;
              padding: 8px;
              border-radius: 4px;
              cursor: pointer; "
                on:click={handleNewConverstionClick}>New conversation</button
              >
            </div>
          {/if}

          <div class="drawer-menu">
            <h3 style="margin: 8px 4px;">Menu</h3>
            <div class="menu-items">
              {#each menuItems as item}
                <button class="menu-item-button" on:click={item.action}>
                  <div
                    style="align-items: center; display: flex; font-size: 16px;"
                  >
                    <div style="margin: 0 8px;">{@html item.icon}</div>
                    <div>{item.label}</div>
                  </div>
                </button>
              {/each}
            </div>
          </div>
        {/if}

        {#if showLanguageSettings && hideDrawerBody}
          <SettingsModal />
        {/if}
      </div>

      <div
        class="drawer-footer"
        style="background-color: #E0DEDE; height: 20px padding: 10px; display: flex; align-items: center; position: absolute; width: 100%; bottom: 0;"
      >
        <p style="font-size: 10px; margin: 5px 8px">
          v{AppDefaults.APP_VERSION}
        </p>
        <a
          href={AppDefaults.CLEANDESK_URL}
          style="text-decoration:none; color: #000; display: flex; align-items: center; margin-left: auto"
        >
          <p style="padding: 6px; margin: 0px; font-size: 12px">
            Powered by CleanDesk Ai
          </p>
          <img
            src={DOMAIN + "/logo96tranparent.png?x=10000000"}
            style="height: 24px; margin-right: 8px"
            alt=""
          />
        </a>
      </div>
    </div>
  {/if}
</section>

<ManageProfileDrawer
  drawerVisible={profileDrawerVisible}
  on:closeDrawer={() => (profileDrawerVisible = !profileDrawerVisible)}
  bind:havi={profileDrawerVisible}
/>
