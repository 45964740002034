import { writable } from "svelte/store";

export const ticketMainId = writable(null);
export const oldTicketMainId = writable(null);

export const messages = writable([]);
export const messageLoading = writable(true);
export const aiMessageLoading = writable(false);
export const disableMessageLoading = writable(false);
export const disableInput = writable(false);

export const callbackDisabled = writable(true);

export const isHelpTextVisible = writable(false);
export const suggestionText = writable([]);

export const selectedMessage = writable(null);
export const selectedConversation = writable(null);

export const startNewAiConversation = writable({});

export const sessionTimeoutId = writable(null);
export const isSessionTimerActive = writable(false);

export const hasUserSentMessage = writable(false);

export const stopMessages = writable(false);

export const settingsMenuItems = writable([]);

export const isSupportListVisible = writable(false);

export const isPageView = writable(false);