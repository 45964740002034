<!-- <svelte:options tag="cleandesk-chat-widget" /> -->
<svelte:options tag="cleandesk-chat-widget" />

<script context="module">
  import Device from "svelte-device-info";
</script>

<!-- <svelte:head>
  <script src="https://www.gstatic.com/firebasejs/7.2.1/firebase-app.js" type="text/javascript"></script>
  <script src="https://www.gstatic.com/firebasejs/7.2.1/firebase-messaging.js" type="text/javascript"></script>
  <script src="https://www.gstatic.com/firebasejs/7.2.1/firebase-analytics.js" type="text/javascript"></script>
</svelte:head> -->

<script>
  // import './firebase-messaging-sw';
  import { onMount } from "svelte";
  // import { getAccessById, getAuthKey, getNonAuthConversationId, getPersonId, getPersonOrgOfficeId, removeAccessById, removeAuthKey, removeNonAuthConversationId, removePersonId, removePersonOrgOfficeId } from "./utils/cookie/user";
  import { getOfficeDetails } from "./utils/GetOfficeDetails";
  // import { logoutUser } from "./utils/LogOutUser";
  import {
    EndSessionCountApi,
    StartSessionCountApi,
  } from "./utils/SessionCountApi";
  import AuthMainFinal from "./components/AuthMainFinal.svelte";
  import {
    DeviceType,
    OrganisationDetails,
    isAuthenticated,
    WidgetUiDetails,
    isShareLink,
    widgetType,
    isDemoWidget,
    demoType,
  } from "./stores/authStores";
  import { getContrastYIQ, hexToRgb } from "./utils/tools/data-transformers";
  import ChatWidget from "./components/ChatWidget/ChatWidget.svelte";
  import { DOMAIN } from "./config/api-variables";
  import FullScreenWidget from "./components/FullScreenWidget/FullScreenWidget.svelte";
  // import EmployeeWidget from "./components/ChatWidget/EmployeeWidget.svelte";
  import DemoSocialMedia from "./components/Demo/DemoSocialMedia.svelte";
  import DemoMobile from "./components/Demo/DemoMobile.svelte";
  import AlertMessageContainer from "./common/AlertMessage/AlertMessageContainer.svelte";

  export let app_id;
  export let app_secret;
  export let access_by_id;

  export let is_demo;
  export let demo_type;

  let isSocialDemo = false;
  let isMobileDemo = false;

  let access_by_type = "customer";

  let cssKeep = "";
  let cssJsKeep = "";

  let primaryColor = "#000fff";
  let secondaryColor = "#000fff";
  let hexToRgbValue = { r: 0, g: 0, b: 0 };
  let textColor = "#000";

  let buttonBorderRadius = "8px";
  let iconDimension = 0;

  let invitationCardVisible = true;

  let buttonDiv;
  let buttonDivHeight;

  let fontFamily = `"Montserrat", sans-serif`;
  // let fontFamily = `"Jersey 10 Charted", sans-serif`;
  // let fontFamily = "Times New Roman, Times, serif";
  // let fontFamily = "Lucida Console, Courier New, monospace";

  document.documentElement.style.setProperty("--fontFamily", fontFamily);

  $: if ($isDemoWidget) {
    if (!isSocialDemo) {
      if ($demoType === "chatbox" && $DeviceType === "desktop") {
        widgetType.set("chatbox");
        document.documentElement.style.setProperty("--fontSize", "14px");
      } else {
        widgetType.set("chatsite");
        document.documentElement.style.setProperty("--fontSize", "16px");
      }
    }
  } else {
    if ($WidgetUiDetails.theme === "chatbox" && $DeviceType === "desktop") {
      widgetType.set("chatbox");
      document.documentElement.style.setProperty("--fontSize", "14px");
    } else {
      widgetType.set("chatsite");
      document.documentElement.style.setProperty("--fontSize", "16px");
    }
  }

  $: {
    if (
      $WidgetUiDetails.shape === "Round" ||
      $WidgetUiDetails.shape === "Oval"
    ) {
      buttonBorderRadius = "50%";
      document.documentElement.style.setProperty("--buttonBorderRadius", "50%");
    } else {
      buttonBorderRadius = "4px";
      document.documentElement.style.setProperty("--buttonBorderRadius", "8px");
    }
  }

  $: {
    if (
      $WidgetUiDetails.shape === "Round" ||
      $WidgetUiDetails.shape === "Oval"
    ) {
      buttonBorderRadius = "50%";
      document.documentElement.style.setProperty("--buttonBorderRadius", "50%");
    } else {
      buttonBorderRadius = "4px";
      document.documentElement.style.setProperty("--buttonBorderRadius", "8px");
    }
  }

  let gtmId = "G-E8B856ZZTV";

  function gtag() {
    dataLayer.push(arguments);
  }

  onMount(async () => {
    window.dataLayer = window.dataLayer || [];
    gtag("js", new Date());
    gtag("config", gtmId);
    var s = document.createElement("script");
    s.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    document.head.append(s);
  });

  const trackButtonClick = () => {
    if (typeof window !== "undefined") {
      gtag("event", "button_click", {
        event_category: "Widget",
        event_label: "widget_button_click",
      });
      console.log("trackButtonClick");
    }
  };

  $: {
    primaryColor = $OrganisationDetails?.primary_color;
    document.documentElement.style.setProperty("--primaryColor", primaryColor);
    if (primaryColor) {
      textColor = getContrastYIQ(primaryColor);
      hexToRgbValue = hexToRgb(primaryColor);
      secondaryColor = `rgba(${hexToRgbValue.r}, ${hexToRgbValue.g}, ${hexToRgbValue.b}, 0.2)`;
      document.documentElement.style.setProperty("--textColor", textColor);
      document.documentElement.style.setProperty(
        "--secondaryColor",
        secondaryColor,
      );
    }
  }

  $: if ($WidgetUiDetails) {
    iconDimension = Math.min(
      $WidgetUiDetails.height || 0,
      $WidgetUiDetails.width || 0,
    );
  }

  onMount(() => {
    EndSessionCountApi();

    getOfficeDetails(app_id);

    isAuthenticated.set(false);

    if (
      document.location.origin + window.location.pathname.split("/")[1] ===
      DOMAIN + "share"
    ) {
      showChatWidget();
      isShareLink.set(true);
    }

    if (is_demo) {
      isDemoWidget.set(true);
      demoType.set(demo_type);
    }

    if (
      demo_type === "instagram" ||
      demo_type === "facebook" ||
      demo_type === "whatsapp"
    ) {
      showChatWidget();
      isSocialDemo = true;
    } else if (demo_type === "mobilesite") {
      isMobileDemo = true;
      showChatWidget();
    }
  });

  if (Device.isPhone) {
    DeviceType.set("mobile");
  } else {
    DeviceType.set("desktop");
  }

  $: if ($demoType === "mobilesite") {
    DeviceType.set("mobile");
  }

  let isVisible = false;
  let firstOpen = false;

  function showChatWidget() {
    isVisible = !isVisible;
    firstOpen = true;
  }

  $: if (!isVisible && firstOpen) {
    EndSessionCountApi();
  }

  // $: if (!!buttonDiv?.clientHeight) {
  //   console.log("testing");
  //   if ($WidgetUiDetails.shape === "Rectangle") {
  //     buttonDivHeight = buttonDiv.clientHeight;
  //   } else {
  //     buttonDivHeight = $WidgetUiDetails.height;
  //   }
  // }

  $: if ($WidgetUiDetails.shape === "Rectangle") {
    buttonDivHeight = buttonDiv?.clientHeight;
  } else {
    buttonDivHeight = $WidgetUiDetails.height;
  }
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<!-- *************************************** -->
{#if !isVisible && !isSocialDemo && !isMobileDemo}
  <div
    class="invitation-card"
    style="
      bottom: {$WidgetUiDetails.distance_bottom &&
      $WidgetUiDetails.distance_bottom + buttonDivHeight + 10}px;
      top: {$WidgetUiDetails.distance_top &&
      $WidgetUiDetails.distance_top + $WidgetUiDetails.height + 30}px;
      right: {$WidgetUiDetails.distance_right}px;
      left: {$WidgetUiDetails.distance_left}px;
      display: {($WidgetUiDetails.chat_invitation_text ||
      $WidgetUiDetails.chat_invitation_image) &&
    invitationCardVisible
      ? 'block'
      : 'none'}
    "
  >
    <button
      class="invitation-card-close-btn"
      on:click={() => (invitationCardVisible = false)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="16px"
        viewBox="0 0 384 512"
        ><path
          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
        /></svg
      >
    </button>
    {#if $WidgetUiDetails.chat_invitation_image}
      <img
        style="border-radius: 4px;"
        src={DOMAIN + $WidgetUiDetails.chat_invitation_image}
        alt="logo"
      />
    {/if}
    {#if $WidgetUiDetails.chat_invitation_text}
      <p style="font-size: 14px;">
        {$WidgetUiDetails.chat_invitation_text}
      </p>
    {/if}
  </div>
  <!-- <button
    on:click={() => {
      showChatWidget();
      trackButtonClick();
    }}
    class="cleandesk-launcher-frame"
    class:cleandesk-launcher-frame-pulsing={$WidgetUiDetails.animation_settings ===
      "Pulsing"}
    style="
      height: {$WidgetUiDetails.height}px;
      width: {$WidgetUiDetails.width}px;
      border-radius: {buttonBorderRadius};
      top: {$WidgetUiDetails.distance_top}px;
      bottom: {$WidgetUiDetails.distance_bottom}px;
      left: {$WidgetUiDetails.distance_left}px;
      right: {$WidgetUiDetails.distance_right}px;
    "
  >
    {#if $WidgetUiDetails.content?.icon && !$WidgetUiDetails.content?.image && !$WidgetUiDetails.content?.text}
      <div style="height: {iconDimension}px; width: {iconDimension}px;">
        <div style="padding: 20%;">
          {@html $WidgetUiDetails.content?.icon}
        </div>
      </div>
    {:else if $WidgetUiDetails.content?.image && !$WidgetUiDetails.content?.icon && !$WidgetUiDetails.content?.text}
      <img src={DOMAIN + $WidgetUiDetails.content?.image} alt="logo" />
    {:else if $WidgetUiDetails.content?.text && !$WidgetUiDetails.content?.icon && !$WidgetUiDetails.content?.image}
      {$WidgetUiDetails.content?.text}
    {/if}
  </button> -->

  {#if $WidgetUiDetails.shape === "Rectangle"}
    <button
      on:click={() => {
        showChatWidget();
        trackButtonClick();
      }}
      class="cleandesk-launcher-frame"
      class:cleandesk-launcher-frame-pulsing={$WidgetUiDetails.animation_settings ===
        "Pulsing"}
      style="
      height: 56px;
      border-radius: {buttonBorderRadius};
      top: {$WidgetUiDetails.distance_top}px;
      bottom: {$WidgetUiDetails.distance_bottom}px;
      left: {$WidgetUiDetails.distance_left}px;
      right: {$WidgetUiDetails.distance_right}px;
      padding: 8px;
    "
      bind:this={buttonDiv}
    >
      {#if !$WidgetUiDetails.content?.text}
        Discover with AI
        <svg
          class="icon-color"
          xmlns="http://www.w3.org/2000/svg"
          height="1em"
          viewBox="0 0 512 512"
          style="padding-left: 4px;"
        >
          <path
            d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4"
          />
        </svg>
      {:else}
        {$WidgetUiDetails.content?.text}
      {/if}
    </button>
  {:else}
    <button
      on:click={() => {
        showChatWidget();
        trackButtonClick();
      }}
      class="cleandesk-launcher-frame"
      class:cleandesk-launcher-frame-pulsing={$WidgetUiDetails.animation_settings ===
        "Pulsing"}
      style="
      height: {$WidgetUiDetails.height}px;
      width: {$WidgetUiDetails.width}px;
      border-radius: {buttonBorderRadius};
      top: {$WidgetUiDetails.distance_top}px;
      bottom: {$WidgetUiDetails.distance_bottom}px;
      left: {$WidgetUiDetails.distance_left}px;
      right: {$WidgetUiDetails.distance_right}px;
    "
      bind:this={buttonDiv}
    >
      {#if $WidgetUiDetails.content?.icon && !$WidgetUiDetails.content?.image && !$WidgetUiDetails.content?.text}
        <div style="height: {iconDimension}px; width: {iconDimension}px;">
          <div style="padding: 20%;">
            {@html $WidgetUiDetails.content?.icon}
          </div>
        </div>
      {:else if $WidgetUiDetails.content?.image && !$WidgetUiDetails.content?.icon && !$WidgetUiDetails.content?.text}
        <img src={DOMAIN + $WidgetUiDetails.content?.image} alt="logo" />
      {:else if $WidgetUiDetails.content?.text && !$WidgetUiDetails.content?.icon && !$WidgetUiDetails.content?.image}
        {$WidgetUiDetails.content?.text}
      {/if}
    </button>
  {/if}

  <!-- fixed shape button with org name -->

  <!-- {#if $OrganisationDetails?.primary_color}
    {#if $DeviceType === "desktop"}
      <div
        class="clean-desk-know-us-container"
        class:cleandesk-launcher-frame-pulsing={$WidgetUiDetails.animation_settings ===
          "Pulsing"}
        style="
          width: 240px;
          padding: 8px 12px;
          border-radius: 4px;
          top: {$WidgetUiDetails.distance_top}px;
          bottom: {$WidgetUiDetails.distance_bottom}px;
          left: {$WidgetUiDetails.distance_left}px;
          right: {$WidgetUiDetails.distance_right}px;
        "
        bind:this={buttonDiv}
      >
        <p style="font-weight: 700;">
          {$OrganisationDetails?.title}'s Web Assist
        </p>
        <button
          on:click={() => {
            showChatWidget();
            trackButtonClick();
          }}
          class="clean-desk-know-us-button-container"
        >
          Know us through conversational AI
          <svg
            class="clean-desk-know-us-icon"
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path
              d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4"
              style="fill: {$OrganisationDetails?.primary_color}"
            />
          </svg>
        </button>
      </div>
    {:else}
      <button
        style="
          width: 56px;
          height: 56px;
          padding: 8px 12px;
          border-radius: 4px;
          font-size: 24px;
          top: {$WidgetUiDetails.distance_top}px;
          bottom: {$WidgetUiDetails.distance_bottom}px;
          left: {$WidgetUiDetails.distance_left}px;
          right: {$WidgetUiDetails.distance_right}px;
        "
        class="clean-desk-know-us-container"
        class:cleandesk-launcher-frame-pulsing={$WidgetUiDetails.animation_settings ===
          "Pulsing"}
        on:click={() => {
          showChatWidget();
          trackButtonClick();
        }}
        bind:this={buttonDiv}
      >
        Ai
      </button>
    {/if}
  {/if} -->
{/if}

{#if firstOpen === true}
  <AuthMainFinal {app_id} {app_secret} {access_by_type} {access_by_id} />
{/if}

<!-- {#if $isAuthenticated === true && firstOpen === true && !is_demo} -->
<!-- {#if $isAuthenticated === true && firstOpen === true && is_demo !== "instagram" && is_demo !== "facebook" && is_demo !== "whatsapp"} -->
{#if $isAuthenticated === true && firstOpen === true && !isSocialDemo && !isMobileDemo}
  <div
    style="{isVisible ? 'display: block' : 'display: none'}; {$DeviceType ===
    'mobile'
      ? 'width: 100%; height: 100%;'
      : ''}"
  >
    <!-- {#if access_by_type === 'customer'} -->
    {#if $widgetType === "chatbox" && $DeviceType === "desktop"}
      <ChatWidget
        {access_by_type}
        {access_by_id}
        {isVisible}
        on:closeWidget={(item) => (isVisible = !item)}
      />
    {:else}
      <FullScreenWidget
        {access_by_type}
        {access_by_id}
        {isVisible}
        on:closeWidget={(item) => (isVisible = !item)}
      />
    {/if}
    <!-- {:else}
       <EmployeeWidget {access_by_type} on:closeWidget={item => isVisible = !item} />
    {/if} -->
  </div>
{/if}

<span style="display: none;" class={cssKeep}
  ><span class={cssKeep} />
  <div></div>
  <div id={cssJsKeep}></div>
  <p></p>
  <ul></ul>
  <li></li>
  <svg></svg><a></a>
  <h3>.</h3>
  <dialog></dialog>
  <hr />
</span>

{#if $isDemoWidget && $isAuthenticated && isSocialDemo && !isMobileDemo}
  <DemoSocialMedia demoType={demo_type} />
{/if}

{#if $isDemoWidget && $isAuthenticated && isMobileDemo && !isSocialDemo}
  <DemoMobile {access_by_type} {access_by_id} {isVisible} {isMobileDemo} />
{/if}

<AlertMessageContainer />

<!-- *************************************** -->

<style>
  .plusIcon {
    fill: #ffffff;
  }

  body :global(body) {
    margin: 0;
    padding: 0;
    font-family: var(--fontFamily);
  }

  div {
    font-family: var(--fontFamily);
  }
  .primary-background-color {
    background-color: var(--primaryColor);
  }
  .primary-text-color {
    color: var(--primaryColor);
  }
  .secondary-background-color {
    background-color: var(--secondaryColor);
    /* background-color: color-mix(in srgb,var(--primaryColor),#0000 75%); */
    /* background-color: linear-gradient(to bottom, var(--primaryColor), rgba(0, 0, 0, 0) 75%); */
  }
  .custom-text-color {
    color: var(--textColor);
  }
  .icon-color {
    fill: var(--textColor);
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .cleandesk-launcher-frame {
    padding: 0;
    border: none;
    z-index: 9999998 !important;
    position: fixed !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
    background: var(--primaryColor) !important;
    color: #fff !important;
    opacity: 1;
    transition:
      box-shadow 0.26s cubic-bezier(0.38, 0, 0.22, 1),
      opacity 0.26s ease-in-out;
  }

  .cleandesk-launcher-frame:hover {
    cursor: pointer;
    opacity: 1;
  }

  .cleandesk-launcher-frame-pulsing {
    transform: translatey(0px);
    animation: pulse 1.5s infinite;
    box-shadow: 0 0 0 0 var(--primaryColor);
    -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  }

  @keyframes pulsing {
    to {
      box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
    }
  }

  .cleandesk-launcher-frame img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .close-widget-button {
    box-shadow:
      0px 2px 4px rgba(0, 18, 26, 0.08),
      0px 2px 16px rgba(0, 18, 26, 0.16);
    z-index: 2147482999 !important;
    position: fixed !important;
    bottom: 590px;
    /* right: 20px; */
    height: 56px !important;
    width: 56px !important;
    border-radius: 100px !important;
    overflow: hidden !important;
    background: var(--primaryColor) !important;
    /* background: #0000ff !important; */
    color: #fff !important;
    opacity: 1;
    transition:
      box-shadow 0.26s cubic-bezier(0.38, 0, 0.22, 1),
      opacity 0.26s ease-in-out;
  }

  .close-widget-button:hover {
    cursor: pointer;
    box-shadow:
      0px 2px 4px rgba(0, 18, 26, 0.08),
      0px 3px 12px rgba(0, 18, 26, 0.16),
      0 2px 14px 0 rgba(0, 18, 26, 0.2);
    opacity: 1;
  }

  .invitation-card {
    position: fixed;
    max-width: 240px;
    max-height: fit-content;
    padding: 12px;
    background-color: #ffffff;
    border-radius: 4px;
    font-family: var(--fontFamily);
    box-shadow: 0px 0px 20px 7px rgba(0, 0, 0, 0.1); /* Smooth shadow */
    animation: floatAnimation 1s infinite alternate; /* Floating animation */
    z-index: 9999988;
  }

  @keyframes floatAnimation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-4px);
    }
  }

  .invitation-card-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    /* padding: 8px; */
    cursor: pointer;
    border: none;
  }

  p {
    font-weight: 400;
    font-family: var(--fontFamily);
    line-height: 1.4;
    font-size: var(--fontSize);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }

  span {
    color: #000;
    line-height: 1.2;
    font-weight: 400;
    font-size: var(--fontSize);
    font-family: var(--fontFamily);
  }

  div {
    line-height: 1.2;
    font-weight: 400;
    font-family: var(--fontFamily);
  }
  .chat-widget-container {
    position: fixed !important;
    bottom: 20px;
    z-index: 9999999 !important;
    width: 25vw !important;
    height: 75vh !important;
    border-radius: 8px !important;
    box-shadow:
      0px 1px 4px rgba(13, 22, 26, 0.08),
      0px 4px 16px rgba(13, 22, 26, 0.12),
      0px 2px 12px rgba(13, 22, 26, 0.08);
    overflow: hidden;
    opacity: 1 !important;
    background: #f3f2f2;

    display: flex;
    flex-direction: column;

    color: #000;
  }

  .chat-list-container {
    color: #000;
    z-index: 9999999 !important;
    background-color: #fff;
    position: fixed !important;
    right: 0;
    bottom: 0;
    box-shadow:
      0px 1px 4px rgba(13, 22, 26, 0.08),
      0px 4px 16px rgba(13, 22, 26, 0.12),
      0px 2px 12px rgba(13, 22, 26, 0.08);
    height: 100%;
  }

  .chat-header-container {
    background-color: var(--primaryColor) !important;
  }

  .chat-listing-header {
    background-color: var(--primaryColor) !important;
  }

  .cleandesk-chat-bar {
    width: 100%;
    background-color: #fff;
    border-top: 0.5px solid #bdbdbd;
    /* border-radius: inherit; */
  }

  .cleandesk-chat-bar-message {
    padding: 12px;
    display: flex;
    align-items: center;
  }

  .cleandesk-chat-bar-message textarea {
    background-color: transparent;
    border-radius: 0;
    border: none;
    font-size: 14px;
    flex: 2;
    line-height: 1.25rem;
    max-height: 100px;
    outline: none;
    overflow-x: hidden;
    resize: none;
    padding: 0;
    margin: 0px 8px;
  }
  .cleandesk-chat-bar-message input {
    background-color: transparent;
    border-radius: 0;
    border: none;
    font-size: 14px;
    flex: 2;
    line-height: 1.25rem;
    max-height: 100px;
    outline: none;
    overflow-x: hidden;
    resize: none;
    padding: 0;
    margin: 0px 8px;
  }
  .btn {
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375em 0.4em;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 2px;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  .send-btn {
    color: rgba(0, 18, 26, 0.59);
    background-color: #fff;
    border-color: #fff;
  }

  .send-btn:hover {
    cursor: pointer;
    color: rgba(0, 18, 26, 0.93);
    background-color: #fff;
    border-color: #fff;
  }

  .cleandesk-chat-body {
    flex: 1;
    background-color: #fff;
    overflow: hidden auto;
  }
  .cleandesk-conversation-container {
    height: 100%;
  }

  .message-item-container {
    margin: 12px 10px;
    color: #000;
    display: flex;
  }

  .message-bubble {
    border-radius: 8px;
    margin: 4px 0px;
    width: 100%;
    background-color: #fff;
    color: #000;
  }

  .message-action-buttons {
    border: none;
    background: none;
    width: fit-content;
    cursor: pointer;
    padding: 0 4px;
  }

  .message-action-icons {
    height: 18px;
    width: 16px;
  }

  .header-profile-name {
    font-size: 14px;
    font-weight: 200;
    font-family: var(--fontFamily);
    text-transform: capitalize;
  }
  .time-ago-style {
    padding-left: 6px;
    font-size: 10px;
    font-weight: 200;
    font-family: var(--fontFamily);
  }
  .more-button-style {
    border: none;
    background: none;
    margin-left: auto;
  }

  @keyframes bouncedelay {
    0%,
    80%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    40% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  @keyframes message-bounce {
    0% {
      transform: scale(0.9);
      -webkit-transform: scale(0.9);
    }
    50% {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
    }
    100% {
      transform: scale(0.9);
      -webkit-transform: scale(0.9);
    }
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45px;
    height: 9px;
    margin-left: -22px;
    margin-top: -13px;
    text-align: center;
  }

  .spinner > div {
    width: 9px;
    height: 9px;
    /* background-color: #dcdcdc; */
    background-color: #333333;
    border-radius: 100%;
    display: inline-block;
    animation: bouncedelay 1400ms ease-in-out infinite;
    animation-fill-mode: both;
  }

  .spinner .bounce1 {
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    animation-delay: -0.16s;
  }

  #container {
    background-color: #2e66bd;
    height: 40px;
  }
  .spinner {
    position: static !important;
    margin-top: -11px;
    margin-left: 0px;
  }

  .spinner div {
    /* background-color: #E0DEDE; */
    /* background-color: #fff; */
  }

  .send-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45px;
    height: 9px;
    margin-left: -22px;
    margin-top: -13px;
    text-align: center;
  }

  .send-spinner > div {
    width: 5px;
    height: 5px;
    background-color: red;
    border-radius: 100%;
    display: inline-block;
    animation: bouncedelay 1400ms ease-in-out infinite;
    animation-fill-mode: both;
  }

  .send-spinner .bounce1 {
    animation-delay: -0.32s;
  }

  .send-spinner .bounce2 {
    animation-delay: -0.16s;
  }

  .send-spinner {
    position: static !important;
    margin-left: -5px;
  }

  .send-spinner div {
    background-color: var(--primaryColor);
    /* background-color: #e0dede; */
  }

  @-webkit-keyframes message-bounce {
    0% {
      transform: scale(0.9);
      -webkit-transform: scale(0.9);
    }
    50% {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
    }
    100% {
      transform: scale(0.9);
      -webkit-transform: scale(0.9);
    }
  }

  @keyframes message-bounce {
    0% {
      transform: scale(0.9);
      -webkit-transform: scale(0.9);
    }
    50% {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
    }
    100% {
      transform: scale(0.9);
      -webkit-transform: scale(0.9);
    }
  }

  #myInput {
    background-image: url(https://www.w3schools.com/css/searchicon.png);
    /* background-position: 249px 6px; */
    background-repeat: no-repeat;
    /* width: 216px; */
    width: calc(100vw - 90px);
    font-size: 16px;
    color: #242424;
    padding: 8px 40px 8px 20px;
    border: 1px solid #ddd;
  }

  ul {
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    width: 100%;
    max-height: 100%;
    /* background-color: white; */
    overflow-x: auto;
    list-style: none;
    padding: 0;
  }

  li {
    box-sizing: border-box;
    transition: 0.2s all;
  }

  .cleandesk-chat-list-item {
    box-sizing: border-box;
    transition: 0.2s all;
    cursor: pointer;
  }

  .cleandesk-chat-list-item:hover {
    background-color: color-mix(in srgb, var(--primaryColor), #0000 75%);
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-button {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
  }

  .chat-widget-input::-webkit-input-placeholder {
    font-style: italic;
    font-size: 10px;
    color: #979797;
  }

  .chat-widget-input:-moz-placeholder {
    font-style: italic;
    font-size: 10px;
    color: #979797;
  }

  .chat-widget-input::-moz-placeholder {
    font-style: italic;
    font-size: 10px;
    color: #979797;
  }

  .chat-widget-input:-ms-input-placeholder {
    font-style: italic;
    font-size: 10px;
    color: #979797;
  }

  .chat-widget-input::input-placeholder {
    font-style: italic;
    font-size: 10px;
    color: #979797;
  }

  .chat-widget-input::placeholder {
    font-style: italic;
    font-size: 10px;
    color: #979797;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .chat-suggestions::-webkit-scrollbar {
    display: none;
  }

  .chat-suggestions {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    height: 29px;
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    overflow-x: scroll;
    padding: 6px;
    padding-bottom: 0;
  }

  .chat-suggestion-item {
    border: 1px solid #a19f9f;
    border-radius: 4px;
    margin: 0px 4px;
    color: #0000ff;
    background: none;
    cursor: pointer;
    background-color: #fff;
  }

  .rating {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .star {
    font-size: 24px;
    cursor: pointer;
    margin: 0 5px;
  }

  .filled {
    color: var(--primaryColor);
  }
  .ticket-pick-up {
    height: 59px;
    width: 100%;
    border: none;
    cursor: pointer;
  }
  .cleandesk-header-profile-name {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
  }

  .drawer {
    background: #f3f2f2;
    position: fixed !important;
    top: 0;
    display: flex;
    height: 100vh;
    opacity: 1;
    z-index: 999999999;
    flex-direction: column;
    transition: transform 750ms 250ms ease-out;
    overflow: hidden;
  }
  .drawer-desktop {
    width: 280px;
    right: 20px;
  }
  .drawer-mobile {
    width: 100%;
    right: 0;
  }
  .menu-items {
    display: flex;
    flex-direction: column;
  }
  .menu-item-button {
    border: none;
    background: none;
    align-items: start;
    display: flex;
    cursor: pointer;
    margin: 4px 8px;
  }

  .chat-list-spinner {
    /* min-height: 275px; */
    height: 100%;
  }

  .chat-list-spinner h3 {
    position: relative;
  }

  .chat-list-spinner h3:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    /* top: 150px; */
    top: calc(100% + 200px);
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    border-radius: 50%;
    border: 4px solid #b1b1b2;
    border-top-color: #323e48;
    -webkit-animation: chat-list-spinner 1s linear infinite;
    animation: chat-list-spinner 1s linear infinite;
  }

  @-webkit-keyframes chat-list-spinner {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes chat-list-spinner {
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  /* from MDN - https://developer.mozilla.org/ */
  .visually-hidden {
    clip: rect(1px, 1px, 1px, 1px) !important;
    border: 0 !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important;
  }

  .disable-button {
    cursor: not-allowed !important;
  }

  .chat-list-tab {
    width: 100%;
    border-width: 1px;
    height: 30px;
    cursor: pointer;
    border: 1px solid #adadad;
  }

  dialog {
    min-width: 300px;
    max-width: 32em;
    border-radius: 8px;
    border: none;
    padding: 20px;
    margin-top: 20px;
  }
  dialog::backdrop {
    background: rgba(0, 0, 0, 0.3);
  }
  dialog > div {
    padding: 1em;
  }
  dialog[open] {
    animation: zoom 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  }
  @keyframes zoom {
    from {
      transform: scale(0.95);
    }
    to {
      transform: scale(1);
    }
  }
  dialog[open]::backdrop {
    animation: fade 0.2s ease-out;
  }
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  hr {
    margin: 4px;
    border-color: white;
  }

  .card-shadow {
    box-shadow:
      0px 1px 4px rgba(13, 22, 26, 0.08),
      0px 4px 16px rgba(13, 22, 26, 0.12),
      0px 2px 12px rgba(13, 22, 26, 0.08);
  }

  .right-side-window-card {
    width: calc(100% - 16px);
    background: #fff;
    margin: 8px;
    border-radius: 8px;
  }

  .tab {
    display: none;
  }

  .tab.active {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  /* product card css */
  .cleandesk-product-main-div {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    padding: 8px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cleandesk-product-content-div {
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .cleandesk-product-img {
    width: 100%;
    /* height: 200px; */
    border-radius: 6px;
    object-fit: cover;
  }

  .cleandesk-product-title {
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Change this value to the number of lines you want to display */
    -webkit-box-orient: vertical;
  }

  .cleandesk-star {
    fill: #ffd700; /* Gold color for filled stars */
    width: 24px;
    height: 24px;
  }

  .cleandesk-product-description {
    font-size: 14px;
    color: #343434;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Change this value to the number of lines you want to display */
    -webkit-box-orient: vertical;
  }

  .cleandesk-price-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cleandesk-price-span {
    font-size: 20px;
    font-weight: 600;
  }
  .cleandesk-buy-button {
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
  }

  /* product list css */

  .cleandesk-product-list {
    height: 100%;
    overflow-y: scroll;
  }

  /* seach main css */
  #cleandesk-search-main {
    border: none;
    background: transparent;
    margin: 0;
    padding: 7px 8px;
    font-size: 16px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
    width: 100%;
  }

  #cleandesk-search-main::placeholder {
    color: #bbb;
  }

  #cleandesk-search-main:focus {
    outline: none;
    /* box-shadow: 0 0 3px 0 #1183d6; */
    /* border-color: #1183d6; */
  }
  .cleandesk-input-nosubmit {
    border: none;
    padding: 0;
  }

  .cleandesk-input-nosubmit {
    border-radius: 4px;
    border: 1px solid #ddd;
    padding-left: 40px;
    background: #fff
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat 13px center;
  }

  .cleandesk-full-screen-widget {
    position: fixed !important;
    bottom: 0px;
    z-index: 9999999 !important;

    width: 100vw !important;
    height: 100% !important;

    box-shadow:
      0px 1px 4px rgba(13, 22, 26, 0.08),
      0px 4px 16px rgba(13, 22, 26, 0.12),
      0px 2px 12px rgba(13, 22, 26, 0.08);
    overflow: hidden;
    opacity: 1 !important;

    display: flex;
    flex-direction: column;

    color: #000;
  }

  .cleandesk-container {
    display: flex;
    height: 100%;
    flex: 1;
    overflow: hidden auto;
  }

  .cleandesk-menu {
    background-color: #fafafa;

    border: 0.25px solid #dcdfe4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cleandesk-menu-bottom-div {
    display: flex;

    flex-direction: column;
    margin-bottom: 1em;
  }

  .cleandesk-second-column {
    background-color: #fff;
    overflow: hidden;
    border-right: 0.25px solid #dcdfe4;
  }

  .cleandesk-third-column {
    flex: 2;

    display: flex;
    flex-direction: column;
    background: #fff;
    height: 100%;
  }

  .cleandesk-third-column-chat-div {
    background: #fff;
    flex: 1;
  }

  .cleandesk-fourth-column {
    flex: 1;
    background-color: #fafafa;

    border: 0.25px solid #dcdfe4;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .cleandesk-toggle-button {
    cursor: pointer;
    padding: 5px;
    background-color: #ddd;
    text-align: center;
    width: 100%;
    border-radius: 12px;
  }

  .cleandesk-menu-expand-button {
    cursor: pointer;
    padding: 5px;
    background: #fff;
    text-align: center;
    width: 100%;
    border: none;
    border-top: 0.5px solid #dcdfe4;
  }

  .cleandesk-menu-container {
    display: flex;
    flex-direction: column;
    height: 88.5%;
    margin-top: 1em;
    overflow: auto;
  }

  .cleandesk-left-menu-item {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    text-align: left;
    border: 0;

    border-radius: 4px;
  }
  .cleandesk-left-menu-item:hover {
    background-color: color-mix(in srgb, var(--primaryColor), #0000 75%);
  }
  .cleandesk-menu-icon-div svg {
    height: 24px;
    width: 24px;
    fill: #000;
  }

  .cleandesk-left-menu-icon {
    height: 24px;
    width: 24px;
    fill: #000;
  }

  .cleandesk-left-name {
    margin-left: 12px;
    font-size: 14px;
    color: #000;
  }

  .cleandesk-full-screen-chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: inherit;
  }

  /* Tooltip text */
  .tooltip {
    position: relative;

    width: 100%;
    display: flex;
    align-items: center;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1 !important;
    top: -5px;
    left: 105%; /* Position the tooltip to the right of the menu item */
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .cleandesk-menu-avatar-button {
    margin: 0px 5% 8px;
    display: flex;
    align-items: center;
    border: 0;
    cursor: pointer;
    border-radius: 4px;
  }

  .cleandesk-menu-avatar-button:hover {
    background-color: color-mix(in srgb, var(--primaryColor), #0000 75%);
  }

  .cleandesk-menu-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border: 0;
    background: none;
  }

  .cleandesk-menu-profile-menu {
    position: relative;

    width: 100%;
    display: flex;
    align-items: center;
  }

  .cleandesk-menu-profile-menu .cleandesk-menu-profile-menu-item {
    visibility: hidden;
    opacity: 0;

    width: max-content;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1 !important;
    bottom: -5px;
    left: 100%; /* Position the tooltip to the right of the menu item */
    transition: opacity 0.3s;
    box-shadow:
      0px 1px 4px rgba(13, 22, 26, 0.08),
      0px 4px 16px rgba(13, 22, 26, 0.12),
      0px 2px 12px rgba(13, 22, 26, 0.08);
  }

  /* Show the tooltip on hover */
  .cleandesk-menu-profile-menu:hover .cleandesk-menu-profile-menu-item {
    visibility: visible;
    opacity: 1;
  }

  .cleandesk-menu-profile-menu-button {
    cursor: pointer;
    padding: 5px 8px;
    background-color: #fff;

    width: 100%;
    border: 0;
    border-radius: 4px;
    display: flex;

    align-items: center;
    gap: 4px;
  }

  .cleandesk-menu-profile-menu-button:hover {
    background-color: color-mix(in srgb, var(--primaryColor), #0000 75%);
  }

  .cleandesk-menu-avatar-dropdown-icon {
    height: 18px;
    width: 18px;
    fill: #000;
  }

  /* product list css */

  .cleandesk-product-list {
    height: 100%;
    overflow-y: scroll;
  }

  .cleandesk-switch-to-regular {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .cleandesk-switch-to-regular-button {
    font-size: 14px;
    border: 1px solid var(--textColor);
    border-radius: 4px;
    padding: 4px 8px;
    cursor: pointer;
  }

  /* msg product card styles */

  .cleandesk-msg-prod-container {
    display: flex;
    padding: 4px 0;
  }
  .cleandesk-main-div {
    border-radius: 12px;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cleandesk-msg-prod-img {
    height: 100%;
    width: 180px;
    border-radius: 8px;
    object-fit: cover;
  }
  .cleandesk-product-desc-div {
    height: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 8px;
  }

  .cleandesk-msg-prod-price-div {
    height: 100%;
    border: 0.25px solid #707070;
    border-radius: 8px;
  }
  .cleandesk-msg-pro-price-inner {
    display: flex;
    flex-direction: column;

    align-items: center;
    padding: 8px;
  }
  .cleandesk-bottom-sheet {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background-color: #f0f0f0;
    z-index: 99999999999999999;
    border-radius: 8px 8px 0 0;
  }

  .cleandesk-bottom-sheet-close {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f3f2f2;
    width: 32px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }

  .clean-desk-know-us-container {
    border: none;
    z-index: 9999998 !important;
    position: fixed !important;
    overflow: hidden !important;
    background: var(--primaryColor) !important;
    color: #fff !important;
    opacity: 1;
  }
  .clean-desk-know-us-button-container {
    position: relative;
    margin-top: 4px;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 30px;
    background-color: #fff;
    color: var(--primaryColor);
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 13px;
  }

  .clean-desk-know-us-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 1em;
    height: 1em;
  }

  /* faceBook chatbox */
  .cleandesk-fb-message {
    position: fixed;
    bottom: 0;
    right: 60px;
    width: 300px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 0px 20px 7px rgba(0, 0, 0, 0.1);
    z-index: 3;
  }

  .cleandesk-fb-container {
    position: relative;
    background-color: #ffffff00;
  }

  .cleandesk-fb-message-top {
    padding: 4px 10px;
    display: flex;
    border-radius: 8px 8px 0px 0px;
    justify-content: space-between;
    background-color: dodgerblue;
  }

  .cleandesk-fb-grid-message {
    height: 300px;
    padding: 10px 5px;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: #fff;
  }

  /* [class^="cleandesk-fb-grid-"] {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  } */

  [class^="cleandesk-fb-grid-"] {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .cleandesk-fb-grid-message > [class^="cleandesk-fb-col-"] {
    margin-top: 1em;
    margin-right: 1em;
  }

  .cleandesk-fb-grid-message > [class^="cleandesk-fb-col-"]:nth-child(-n + 1) {
    margin-top: 0;
  }

  .cleandesk-fb-grid-message > [class^="cleandesk-fb-col-"]:nth-child(1n) {
    margin-right: 0;
  }

  .cleandesk-fb-col-message-sent {
    margin-left: calc(8.33333333% + 0.08333333em) !important;
  }

  .cleandesk-fb-col-message-received {
    display: flex;
    gap: 4px;
    align-items: flex-end;
    margin-right: calc(8.33333333% + 0.08333333em) !important;
  }

  .cleandesk-fb-col-message-sent,
  .cleandesk-fb-col-message-received {
    width: calc(91.66666667% - 0.08235677em);
  }

  .cleandesk-fb-message-sent,
  .cleandesk-fb-message-received {
    margin-top: 0.0625em;
    margin-bottom: 0.0625em;
    padding: 0.25em 1em;
    font-size: 15px;
  }

  .cleandesk-fb-message-sent div,
  .cleandesk-fb-message-received div {
    margin: 0;
    line-height: 1;
  }

  .cleandesk-fb-message-sent {
    float: right;
    color: white;
    margin: 5px 0px;
    background-color: dodgerblue;
    border-radius: 1em 0.25em 0.25em 1em;
  }

  .cleandesk-fb-message-sent:first-child {
    border-radius: 1em 1em 0.25em 1em;
  }

  .cleandesk-fb-message-sent:last-child {
    border-radius: 1em 0.25em 1em 1em;
  }

  .cleandesk-fb-message-sent:only-child {
    border-radius: 1em;
  }

  .cleandesk-fb-message-received {
    float: left;
    color: black;
    margin: 5px 0px;
    background-color: rgb(233, 233, 233);
    border-radius: 0.25em 1em 1em 0.25em;
  }

  .cleandesk-fb-message-received:first-child {
    border-radius: 1em 1em 1em 0.25em;
  }

  .cleandesk-fb-message-received:last-child {
    border-radius: 0.25em 1em 1em 1em;
  }

  .cleandesk-fb-message-received:only-child {
    border-radius: 1em;
  }

  .cleandesk-fb-message-box {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
  }

  .cleandesk-fb-message-input:focus {
    outline: none;
  }

  .cleandesk-fb-col-message-sent {
    margin-top: 0.25em !important;
  }

  .cleandesk-fb-col-message-received {
    margin-top: 0.25em !important;
  }

  /* whats app chat */

  .cleandesk-wa-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cleandesk-wa-mobile-phone {
    margin: auto;
    padding: 10px 10px 20px;
    width: 240px;
    height: 480px;
    background-color: #2b2a2a;
    box-shadow: 0 0 20px #e2e2e2;
    border-radius: 30px;
  }

  .cleandesk-wa-screen-phone {
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    border-radius: 20px;
    overflow-y: auto;
    position: relative;
    background-color: #ffffff00;
    /* overflow: hidden; */
  }

  .cleandesk-wa-screen-container {
    height: 100%;
  }

  .cleandesk-wa-chat {
    height: calc(100% - 43px);
  }

  .cleandesk-wa-chat-container {
    height: 100%;
  }

  .cleandesk-wa-user-bar {
    height: 55px;
    background: #005e54;
    color: #fff;
    padding: 0 8px;
    font-size: 20px;
    position: relative;
    z-index: 1;
  }

  .cleandesk-wa-user-bar:after {
    content: "";
    display: table;
    clear: both;
  }

  .cleandesk-wa-user-bar div {
    float: left;
    transform: translateY(-50%);
    position: relative;
    top: 50%;
  }

  .cleandesk-wa-user-bar .cleandesk-wa-avatar {
    margin: 0 0 0 5px;
    width: 30px;
    height: 30px;
  }

  .cleandesk-wa-user-bar .cleandesk-wa-avatar img {
    border-radius: 50%;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
    display: block;
    width: 100%;
  }

  .cleandesk-wa-user-bar .cleandesk-wa-name {
    font-size: 12px;
    font-weight: 600;
    text-overflow: ellipsis;
    letter-spacing: 0.3px;
    margin: 0 0 0 8px;
    overflow: hidden;
    white-space: nowrap;
    width: 95px;
  }

  .cleandesk-wa-user-bar .cleandesk-wa-name span {
    color: #fff;
  }

  .cleandesk-wa-user-bar .cleandesk-wa-status {
    display: block;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0;
    color: #fff;
  }

  .cleandesk-wa-conversation {
    height: calc(100% - 12px);
    position: relative;
    background: #efe7dd
      url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg")
      repeat;
    z-index: 0;
  }

  .cleandesk-wa-conversation ::-webkit-scrollbar {
    transition: all 0.5s;
    width: 5px;
    height: 1px;
    z-index: 10;
  }

  .cleandesk-wa-conversation ::-webkit-scrollbar-track {
    background: transparent;
  }

  .cleandesk-wa-conversation ::-webkit-scrollbar-thumb {
    background: #b3ada7;
  }

  .cleandesk-wa-conversation .cleandesk-wa-conversation-container {
    height: calc(100% - 58px);
    max-height: calc(100% - 58px);
    width: 208px;
    box-shadow: inset 0 10px 10px -10px #000000;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 16px;
    margin-bottom: 5px;
  }

  .cleandesk-wa-conversation .cleandesk-wa-conversation-container:after {
    content: "";
    display: table;
    clear: both;
  }

  .cleandesk-wa-message {
    color: #000;
    clear: both;
    line-height: 18px;
    font-size: 14px;
    padding: 8px;
    position: relative;
    margin: 8px 0;
    max-width: 85%;
    word-wrap: break-word;
    z-index: -1;
  }

  .cleandesk-wa-message:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
  }

  .cleandesk-wa-metadata {
    display: inline-block;
    float: right;
    padding: 0 0 0 7px;
    position: relative;
    bottom: -4px;
  }

  .cleandesk-wa-metadata .cleandesk-wa-time {
    color: rgba(0, 0, 0, 0.45);
    font-size: 11px;
    display: inline-block;
  }

  .cleandesk-wa-metadata .cleandesk-wa-tick {
    display: inline-block;
    margin-left: 2px;
    position: relative;
    top: 4px;
    height: 16px;
    width: 16px;
  }

  .cleandesk-wa-metadata .cleandesk-wa-tick svg {
    position: absolute;
    transition: 0.5s ease-in-out;
  }

  .cleandesk-wa-metadata .cleandesk-wa-tick svg:first-child {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: perspective(800px) rotateY(180deg);
    transform: perspective(800px) rotateY(180deg);
  }

  .cleandesk-wa-metadata .cleandesk-wa-tick svg:last-child {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: perspective(800px) rotateY(0deg);
    transform: perspective(800px) rotateY(0deg);
  }

  .cleandesk-wa-metadata .cleandesk-wa-tick-animation svg:first-child {
    -webkit-transform: perspective(800px) rotateY(0);
    transform: perspective(800px) rotateY(0);
  }

  .cleandesk-wa-metadata .cleandesk-wa-tick-animation svg:last-child {
    -webkit-transform: perspective(800px) rotateY(-179.9deg);
    transform: perspective(800px) rotateY(-179.9deg);
  }

  .cleandesk-wa-message:first-child {
    margin: 16px 0 8px;
  }

  .cleandesk-wa-message.cleandesk-wa-received {
    background: #fff;
    border-radius: 0px 5px 5px 5px;
    float: left;
  }

  .cleandesk-wa-message.cleandesk-wa-received .cleandesk-wa-metadata {
    padding: 0 0 0 16px;
  }

  .cleandesk-wa-message.cleandesk-wa-received:after {
    border-width: 0px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
    top: 0;
    left: -10px;
  }

  .cleandesk-wa-message.cleandesk-wa-sent {
    background: #e1ffc7;
    border-radius: 5px 0px 5px 5px;
    float: right;
  }

  .cleandesk-wa-message.cleandesk-wa-sent:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #e1ffc7;
    top: 0;
    right: -10px;
  }

  .cleandesk-wa-conversation-compose {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    overflow: hidden;
    height: 35px;
    width: 100%;
    z-index: 2;
  }

  .cleandesk-wa-conversation-compose div,
  .cleandesk-wa-conversation-compose input {
    background: #fff;
    height: 100%;
  }

  .cleandesk-wa-conversation-compose .cleandesk-wa-emoji {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 5px 0 0 5px;
    flex: 0 0 auto;
    margin-left: 8px;
    width: 38px;
  }

  .cleandesk-wa-conversation-compose .cleandesk-wa-input-msg {
    border: 0;
    flex: 1 1 auto;
    font-size: 12px;
    margin: 0;
    outline: none;
    min-width: 50px;
  }

  .cleandesk-wa-conversation-compose .cleandesk-wa-photo {
    flex: 0 0 auto;
    border-radius: 0px 5px 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
  }

  .cleandesk-wa-conversation-compose .cleandesk-wa-photo:after {
    border-width: 0px 0 10px 10px;
    border-color: transparent transparent transparent #fff;
    border-style: solid;
  }

  .cleandesk-wa-conversation-compose .cleandesk-wa-send {
    background: transparent;
    border: 0;
    cursor: pointer;
    flex: 0 0 auto;
    margin-left: 8px;
    margin-right: 8px;
    padding: 0;
    position: relative;
    outline: none;
  }

  .cleandesk-wa-conversation-compose .cleandesk-wa-send .cleandesk-wa-circle {
    background: #008a7c;
    border-radius: 50%;
    color: #fff;
    position: relative;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* instagram chat */
  .cleandesk-ig-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cleandesk-ig-mobile-phone {
    margin: auto;
    padding: 10px 10px 20px;
    width: 240px;
    height: 480px;
    background-color: #2b2a2a;
    box-shadow: 0 0 20px #e2e2e2;
    border-radius: 30px;
  }

  .cleandesk-ig-screen-phone {
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    border-radius: 20px;
    overflow-y: auto;
    position: relative;
    background-color: #ffffff00;
  }

  .cleandesk-ig-screen-container {
    height: 100%;
  }

  .cleandesk-ig-chat {
    height: calc(100% - 43px);
  }

  .cleandesk-ig-chat-container {
    height: 100%;
  }

  .cleandesk-ig-user-bar {
    height: 55px;
    background: white;
    color: #fff;
    padding: 0 8px;
    font-size: 20px;
    position: relative;
    z-index: 1;
  }

  .cleandesk-ig-user-bar:after {
    content: "";
    display: table;
    clear: both;
  }

  .cleandesk-ig-user-bar div {
    float: left;
    transform: translateY(-50%);
    position: relative;
    top: 50%;
  }

  .cleandesk-ig-user-bar .cleandesk-ig-avatar {
    margin: 0 0 0 5px;
    width: 20px;
    height: 20px;
  }

  .cleandesk-ig-user-bar .cleandesk-ig-name {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    text-overflow: ellipsis;
    letter-spacing: 0.3px;
    margin: 0 0 0 8px;
    overflow: hidden;
    white-space: nowrap;
    width: 95px;
  }

  .cleandesk-ig-conversation {
    height: calc(100% - 12px);
    position: relative;
    background: white;
    z-index: 0;
  }

  .cleandesk-ig-conversation ::-webkit-scrollbar {
    transition: all 0.5s;
    width: 5px;
    height: 1px;
    z-index: 10;
  }

  .cleandesk-ig-conversation ::-webkit-scrollbar-track {
    background: transparent;
  }

  .cleandesk-ig-conversation ::-webkit-scrollbar-thumb {
    background: #b3ada7;
  }

  .cleandesk-ig-profile-preview {
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cleandesk-ig-conversation .cleandesk-ig-conversation-container {
    height: calc(100% - 58px);
    max-height: calc(100% - 58px);
    width: 208px;
    box-shadow: inset 0px 10px 10px -15px rgba(143, 143, 143, 1);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 16px;
    margin-bottom: 5px;
  }

  .cleandesk-ig-message {
    color: #000;
    clear: both;
    line-height: 14px;
    font-size: 12px;
    padding: 8px;
    position: relative;
    margin: 8px 0;
    max-width: 85%;
    word-wrap: break-word;
    z-index: -1;
  }

  .cleandesk-ig-message:first-child {
    margin: 16px 0 8px;
  }

  .cleandesk-ig-message.cleandesk-ig-received {
    padding: 8px 20px;
    background: rgb(233, 233, 233);
    border-radius: 30px;
    float: left;
  }

  .cleandesk-ig-message.cleandesk-ig-sent {
    padding: 8px 10px;
    background: #523bea;
    color: white;
    border-radius: 5px 0px 5px 5px;
    border-radius: 30px;
    float: right;
  }

  .cleandesk-ig-conversation-compose {
    margin: auto;
    padding: 4px 10px 4px 0px;
    display: flex;
    background-color: rgb(233, 233, 233);
    border-radius: 30px;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    height: 25px;
    width: 93%;
    z-index: 2;
  }

  .cleandesk-ig-conversation-compose div,
  .cleandesk-ig-conversation-compose input {
    background: transparent;
    height: 100%;
  }

  .cleandesk-ig-conversation-compose .cleandesk-ig-cam {
    display: flex;
    align-items: center;
    justify-content: center;
    background: blue;
    border-radius: 50%;
    flex: 0 0 auto;
    margin-left: 8px;
    width: 25px;
    height: 25px;
    padding: 1px;
  }

  .cleandesk-ig-conversation-compose .cleandesk-ig-input-msg {
    border: 0;
    flex: 1 1 auto;
    font-size: 12px;
    margin: 0;
    outline: none;
    min-width: 50px;
    padding-left: 6px;
  }

  .cleandesk-ig-conversation-compose .cleandesk-ig-photo {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
  }

  .cleandesk-demo-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .cleandesk-demo-loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid black;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .cleandesk-mobile-demo-resize {
    transform: scale(0.7); /* Scale down to 70% of original size */
    -webkit-transform: scale(0.7); /* Safari and Chrome */
    -moz-transform: scale(0.7); /* Firefox */
    -ms-transform: scale(0.7); /* Internet Explorer */
    -o-transform: scale(0.7); /* Opera */
    transform-origin: top left; /* Scale from top left corner */
    -webkit-transform-origin: top left; /* Safari and Chrome */
    -moz-transform-origin: top left; /* Firefox */
    -ms-transform-origin: top left; /* Internet Explorer */
    -o-transform-origin: top left; /* Opera */
    width: 142.857%; /* Adjust width to counteract scaling (1 / 0.7) */
    height: 142.857%; /* Adjust height to counteract scaling (1 / 0.7) */
    width: 142.857% !important;
    height: 142.857% !important;
    position: relative !important;
  }

  .cleandesk-alert-message {
    position: fixed;
    top: 20px;
    right: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 9px 12px;
    color: #000;
    border-radius: 4px;
    box-shadow:
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);
    font-size: 14px;
    display: flex;
    align-items: center;
    width: fit-content;
    background-color: #fff;
    z-index: 2147483647;
  }

  .cleandesk-carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    margin: auto;
  }

  .cleandesk-carousel-inner {
    display: flex;
    transition: transform 0.5s ease;
  }

  .cleandesk-carousel-item {
    min-width: 100%;
    box-sizing: border-box;
  }

  .cleandesk-carousel-item img {
    width: 100%;
    display: block;
  }

  .cleandesk-carousel-controls {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }

  .cleandesk-carousel-control {
    color: white;
    border: none;
    padding: 1rem;
    cursor: pointer;
  }
</style>
