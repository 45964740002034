import { writable } from "svelte/store";

export const isAuthenticated = writable(false);

export const userDetails = writable(null);

export const selectedLanguage = writable("English");

export const officeDetails = writable(null);

export const OrganisationDetails = writable(null);
// export const OrganisationDetails = writable({
//   logo: "/logo48.png?x=10000000",
//   primary_color: "#0000ff",
//   title: "CleanDesk AI",
//   home_page_banner_urls: [],
// });

export const WidgetUiDetails = writable({});

export const DeviceType = writable(null);

export const isShareLink = writable(false);

export const widgetType = writable("chatsite");

export const isDemoWidget = writable(false);

export const demoType = writable(false);

