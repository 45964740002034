<svelte:options tag="cleandesk-menu-avatar" />

<script>
  import { DOMAIN } from "../../config/api-variables";
  import { OrganisationDetails, userDetails } from "../../stores/authStores";
  import { createEventDispatcher } from "svelte";
  import { capitalizeWords } from "../../utils/tools/data-transformers";

  const dispatch = createEventDispatcher();

  export let expanded;

  let avatarLetter;
  let avatarColor;

  $: {
    avatarLetter = $userDetails?.first_name?.charAt(0).toUpperCase();
    // avatarColor = generateColorFromNumber($userDetails?.person_id);
    avatarColor = $OrganisationDetails?.primary_color;
  }

  $: console.log($userDetails, "$userDetails");
</script>

<button
  class="cleandesk-menu-avatar-button"
  style={!expanded
    ? "justify-content: center; padding: 8px 0; margin: 0px 0.2em 8px;"
    : "padding: 8px; margin: 0px 1em 8px;"}
>
  <div
    class="cleandesk-menu-profile-menu"
    style={!expanded && "justify-content: center;"}
  >
    {#if !expanded}
      <div class="cleandesk-menu-avatar" style="">
        {#if $userDetails?.profile_image !== "/media/default/profile/person/default.png"}
          <img
            src={DOMAIN + $userDetails?.profile_image}
            alt="A"
            style="height: 36px; border-radius: 50%;"
          />
        {:else}
          <div
            class="avatar"
            style="background-color: {avatarColor}; width: 36px;height: 36px;display: flex;align-items: center;justify-content: center;border-radius: 50%;color: white;font-size: 14px;"
          >
            {#if $userDetails?.title === "Anonymous"}
              Y
            {:else}
              {avatarLetter}
            {/if}
          </div>
        {/if}
      </div>
    {:else}
      <div class="cleandesk-menu-avatar" style="">
        {#if $userDetails?.profile_image !== "/media/default/profile/person/default.png" && $userDetails?.profile_image !== "/media/default/aiassistant/agent.png"}
          <img
            src={DOMAIN + $userDetails?.profile_image}
            alt="A"
            style="height: 36px; border-radius: 50%;"
          />
        {:else}
          <div
            class="avatar"
            style="background-color: {avatarColor}; width: 36px;height: 36px;display: flex;align-items: center;justify-content: center;border-radius: 50%;color: white;font-size: 14px;"
          >
            {#if $userDetails?.first_name === "Anonymous"}
              Y
            {:else}
              {avatarLetter}
            {/if}
          </div>
        {/if}
      </div>
      <span class="cleandesk-menu-avatar-name" style="margin-left: 12px;">
        {#if $userDetails?.first_name === "Anonymous"}
          You
        {:else}
          {capitalizeWords(
            $userDetails?.first_name + " " + $userDetails?.last_name
          )}
        {/if}
      </span>
    {/if}
    <span class="cleandesk-menu-profile-menu-item">
      <!-- <button class="cleandesk-menu-profile-menu-button">
        <svg
          class="cleandesk-menu-avatar-dropdown-icon"
          xmlns="http://www.w3.org/2000/svg"
          height="18"
          viewBox="0 -960 960 960"
          width="18"
          ><path
            d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z"
          /></svg
        >
        Settings
      </button>
      <button class="cleandesk-menu-profile-menu-button">
        <svg
          class="cleandesk-menu-avatar-dropdown-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          ><path
            d="M480-80q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 85-29 163.5T688-214L560-342q-18 11-38.5 16.5T480-320q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 22-5.5 42.5T618-398l60 60q20-41 31-86t11-92v-189l-240-90-240 90v189q0 121 68 220t172 132q26-8 49.5-20.5T576-214l56 56q-33 27-71.5 47T480-80Zm0-320q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400Zm8-77Z"
          /></svg
        >
        Terms of Services</button
      >
      <button class="cleandesk-menu-profile-menu-button">
        <svg
          class="cleandesk-menu-avatar-dropdown-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          ><path
            d="M480-440q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0-80q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0 440q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-400Zm0-315-240 90v189q0 54 15 105t41 96q42-21 88-33t96-12q50 0 96 12t88 33q26-45 41-96t15-105v-189l-240-90Zm0 515q-36 0-70 8t-65 22q29 30 63 52t72 34q38-12 72-34t63-52q-31-14-65-22t-70-8Z"
          /></svg
        >
        Privacy Policy</button
      > -->
      <button
        on:click={() => dispatch("languageModalVisible")}
        class="cleandesk-menu-profile-menu-button"
      >
        <svg
          class="cleandesk-menu-avatar-dropdown-icon"
          xmlns="http://www.w3.org/2000/svg"
          height="18"
          viewBox="0 -960 960 960"
          width="18"
          ><path
            d="m476-80 182-480h84L924-80h-84l-43-122H603L560-80h-84ZM160-200l-56-56 202-202q-35-35-63.5-80T190-640h84q20 39 40 68t48 58q33-33 68.5-92.5T484-720H40v-80h280v-80h80v80h280v80H564q-21 72-63 148t-83 116l96 98-30 82-122-125-202 201Zm468-72h144l-72-204-72 204Z"
          /></svg
        >
        Language</button
      >
      <!-- <button class="cleandesk-menu-profile-menu-button">
        <svg
          class="cleandesk-menu-avatar-dropdown-icon"
          xmlns="http://www.w3.org/2000/svg"
          height="18"
          viewBox="0 -960 960 960"
          width="18"
          ><path
            d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z"
          /></svg
        >Logout</button
      > -->
    </span>
  </div>
</button>
