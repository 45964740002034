<svelte:options tag="cleandesk-auth-main-final" />

<script>
  import { onMount, onDestroy } from "svelte";
  import {
    getAccessById,
    getAuthKey,
    getDeviceData,
    logoutClearLocal,
    setAccessById,
    setAuthKey,
    setPersonId,
    setPersonOrgOfficeId,
  } from "../utils/cookie/user";
  import axios from "axios";
  import {
    isAuthenticated,
    isShareLink,
    userDetails,
  } from "../stores/authStores";
  import {
    CHAT_DOMAIN,
    PROFILE_MANAGEMENT_MODULE,
    REGISTRATION_MODULE,
  } from "../config/api-variables";
  import { logoutUser } from "../utils/LogOutUser";
  // import io from "socket.io-client";
  // import { chatSocket } from "../utils/socket/socketConfig";
  import { resetChatSocket } from "../utils/socket/socketManager";
  import { removeHttpHttpsWww } from "../utils/tools/data-transformers";

  export let app_id;
  export let app_secret;
  export let access_by_type;
  export let access_by_id;

  onMount(() => {
    // if (app_id && app_secret && access_by_type) {

    //   if (!getAuthKey()) {
    //     // if there is no auth key then generate it
    //     generateToken();
    //   } else {
    //     // if there is auth key then check if access_by_id is same as the one in cookie
    //     if (getAccessById() !== access_by_id) {
    //       logoutUser();
    //       logoutClearLocal();
    //       generateToken();
    //     } else {
    //       fetchProfile();
    //     }
    //   }

    // } else {
    //   alert("There was problem while getting user data! Please try again later.");
    // }

    if (!getAuthKey()) {
      // if there is no auth key then generate it
      generateToken();
    } else {
      // if there is auth key then check if access_by_id is same as the one in cookie
      if (getAccessById() !== access_by_id || $isShareLink) {
        logoutUser();
        // logoutClearLocal();
        generateToken();
      } else {
        fetchProfile();
      }
    }
  });

  onDestroy(() => console.log("The component has been destroyed"));

  const generateToken = () => {
    const authHeaderConfig = {
      headers: {},
    };

    if (app_id && app_secret) {
      authHeaderConfig.headers = {
        "x-client-id": app_id,
        "x-client-secret": app_secret,
        devicedetails: getDeviceData(),
      };
    } else if ($isShareLink) {
      const slugName = window.location.pathname.split("/")[2];

      authHeaderConfig.headers = {
        "x-client-slug": slugName,
        devicedetails: getDeviceData(),
      };
    } else {
      authHeaderConfig.headers = {
        "x-client-domain": removeHttpHttpsWww(window.location.hostname),
        devicedetails: getDeviceData(),
      };
    }

    axios
      .post(
        REGISTRATION_MODULE + "/generate/gateway/auth/token",
        { access_by_id: access_by_id, access_by_type: access_by_type },
        authHeaderConfig,
      )
      .then((response) => {
        if (response.data.statusCode === "S10001") {
          setAuthKey(response.data.rows.token);
          setPersonId(response.data.rows.person_id);
          setPersonOrgOfficeId(response.data.rows.organisation_office_id);
          setAccessById(access_by_id);
          fetchProfile();
        } else {
          alert(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        alert(error.response.data.detail);
      });
  };

  const fetchProfile = () => {
    const headers = { Authorization: "Token " + getAuthKey() };

    axios
      .post(
        PROFILE_MANAGEMENT_MODULE + "/profile/",
        { person_id: null },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          userDetails.set(response?.data?.rows);
          isAuthenticated.set(true);

          // const socketConnection = io(CHAT_DOMAIN, {
          //   query: {
          //     token: getAuthKey(),
          //   },
          // });

          // chatSocket.set(socketConnection);
          resetChatSocket();
        }
        if (response.data.statusCode === "E100001")
          alert(response.data.message);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };
</script>
