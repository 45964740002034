<svelte:options tag="cleandesk-left-side-menu" />

<script>
  import { createEventDispatcher } from "svelte";
  import MenuButton from "./MenuButton.svelte";
  import StartNewConversationButton from "../../common/components/StartNewConversationButton.svelte";
  import { LEGISLATOR_MANAGEMENT_MODULE } from "../../config/api-variables";
  import { getAuthKey, getPersonOrgOfficeId } from "../../utils/cookie/user";
  import { onMount } from "svelte";
  import axios from "axios";
  import { startNewAiConversation } from "../../stores/chatStores";
  import {
    menuItemsList,
    menuItemsLoading,
    selectedMenuItem,
  } from "../../stores/menuStores";

  export let expanded;
  let selectedMenu;

  const removeTheseIds = [12, 22];
  let removedItems = [];

  const dispatch = createEventDispatcher();

  const homeClicked = (e) => {
    // if (!e.detail.item.master_menu_id === 2) {
    // }
    selectedMenu = e.detail.item;
    console.log(e.detail.item, "e.detail");
    dispatch("menuItemClick", e.detail);
  };

  const expandLeftMenu = () => {
    dispatch("expandMenu");
  };

  let maxVisibleItems = 4; // Maximum number of items visible in the menu

  const havinesh = () => {
    maxVisibleItems = maxVisibleItems === 4 ? 100000 : 4;
  };

  let visibleItems = [];
  let hiddenItems = [];
  let menuItems = [];

  onMount(() => {
    fetchMenuList();
  });

  $: {
    const menuMap = new Map();
    menuItems.forEach((item) => menuMap.set(item.id, item));

    let updated = true;
    while (updated) {
      updated = false;
      // Iterate through menuItems to create the hierarchical structure
      menuItems.forEach((item) => {
        if (item.parent_menu_id !== null) {
          const parentItem = menuMap.get(item.parent_menu_id);
          if (parentItem) {
            if (!parentItem.children) {
              parentItem.children = [];
            }
            // Check if the child item is not already in the parent's children array
            if (!parentItem.children.some((child) => child.id === item.id)) {
              parentItem.children.push(item);
              // Remove child item from the main array
              const index = menuItems.findIndex((i) => i.id === item.id);
              if (index !== -1) {
                menuItems.splice(index, 1);
                updated = true;
              }
            }
          }
        }
      });
    }
  }

  $: {
    if (menuItems.length <= maxVisibleItems) {
      visibleItems = menuItems;
      hiddenItems = [];
    } else {
      visibleItems = menuItems.slice(0, maxVisibleItems);
      hiddenItems = menuItems.slice(maxVisibleItems);
    }
  }

  $: if (visibleItems) {
    visibleItems = visibleItems.sort(
      (a, b) => parseFloat(a.weight) - parseFloat(b.weight),
    );
  }

  // $: if (menuItems.length > 0) {
  //   removedItems = menuItems.filter((item) => {
  //     if (removeTheseIds.includes(item.master_menu_id)) {
  //       // If master_menu_id is in removeTheseIds, remove it from menuItems
  //       return true; // Include in removedItems
  //     }
  //     return false; // Exclude from removedItems
  //   });

  //   // Update menuItems to exclude removed items
  //   menuItems = menuItems.filter(
  //     (item) => !removeTheseIds.includes(item.master_menu_id)
  //   );
  // }

  const fetchMenuList = () => {
    const headers = { Authorization: "Token " + getAuthKey() };

    axios
      .post(
        LEGISLATOR_MANAGEMENT_MODULE + "/menu/visible/list",
        { organisation_office_id: getPersonOrgOfficeId() },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          menuItems = response?.data?.rows;
          menuItemsLoading.set(false);
          menuItemsList.set(menuItems);
          if (menuItems.length > 0) {
            // console.log("menuItems.length > 0", menuItems[0]);
            // selectedMenu = menuItems[0];
            // startNewAiConversation.set({
            //   startNew: true,
            //   menu_id: menuItems[0].id,
            //   parent_menu_id: menuItems[0].parent_menu_id,
            //   menu_request_type: "intro",
            // });
            selectedMenuItem.set(menuItems[0]);
          } else {
            // console.log("menuItems.length <= 0");
            // startNewAiConversation.set({
            //   startNew: true,
            //   menu_id: null,
            //   parent_menu_id: null,
            //   menu_request_type: null,
            // });
            selectedMenuItem.set({ id: null });
          }
        }
        if (response.data.statusCode === "E100001")
          alert(response.data.message);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  let moreMenu;

  $: if (maxVisibleItems) {
    moreMenu = {
      id: "more",
      title: maxVisibleItems === 4 ? "More" : "Less",
      name: "More",
      icon: `<svg class="cleandesk-left-menu-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400Z"/></svg>`,
    };
  }
</script>

<StartNewConversationButton
  primary={true}
  {expanded}
  extraStyles="margin-bottom: 8px;"
/>

{#each visibleItems as menuItem}
  <MenuButton
    {menuItem}
    {expanded}
    isSelected={$selectedMenuItem?.id === menuItem?.id}
    selectedMenuItem={$selectedMenuItem}
    on:menuItemClick={homeClicked}
    on:expandMenu={expandLeftMenu}
  />
{/each}

{#if menuItems.length > 4}
  <MenuButton
    menuItem={moreMenu}
    {expanded}
    on:menuItemClick={havinesh}
    on:expandMenu={expandLeftMenu}
  />
{/if}
