<svelte:options tag="cleandesk-mobile-bottom-sheet" />

<script>
  import MenuButton from "../../components/Common/MenuButton.svelte";
  import MobileBottomMenu from "../../components/Common/MobileBottomMenu.svelte";
  import { fly } from "svelte/transition";
  import { createEventDispatcher, onMount } from "svelte";
  import { LEGISLATOR_MANAGEMENT_MODULE } from "../../config/api-variables";
  import { getAuthKey, getPersonOrgOfficeId } from "../../utils/cookie/user";
  import axios from "axios";
  import { DeviceType } from "../../stores/authStores";
  import { startNewAiConversation } from "../../stores/chatStores";
  import {
    menuItemsList,
    menuItemsLoading,
    selectedMenuItem,
  } from "../../stores/menuStores";

  export let expanded = false;
  export let isWidget = false;

  const dispatch = createEventDispatcher();

  let moreExpanded = false;

  let maxVisibleItems = 4;

  $: if (isWidget && $DeviceType !== "mobile") {
    maxVisibleItems = 3;
  }

  let selectedMenu;

  let visibleItems = [];
  let hiddenItems = [];

  let expanMenuItem;

  let menuItems = [];

  const homeClicked = (e) => {
    if (!!e.detail.item.children) {
      moreExpanded = true;
      expanMenuItem = e.detail.item;
      selectedMenu = e.detail.item;
    } else {
      moreExpanded = false;
      console.log(e.detail.item, "e.detail");
      dispatch("menuItemClick", e.detail);
    }
  };

  const expandLeftMenu = () => {
    console.log("expandLeftMenu");
    // dispatch("expandMenu");
    moreExpanded = true;
  };

  onMount(() => {
    fetchMenuList();
  });

  $: {
    const menuMap = new Map();
    menuItems.forEach((item) => menuMap.set(item.id, item));

    let updated = true;
    while (updated) {
      updated = false;
      // Iterate through menuItems to create the hierarchical structure
      menuItems.forEach((item) => {
        if (item.parent_menu_id !== null) {
          const parentItem = menuMap.get(item.parent_menu_id);
          if (parentItem) {
            if (!parentItem.children) {
              parentItem.children = [];
            }
            // Check if the child item is not already in the parent's children array
            if (!parentItem.children.some((child) => child.id === item.id)) {
              parentItem.children.push(item);
              // Remove child item from the main array
              const index = menuItems.findIndex((i) => i.id === item.id);
              if (index !== -1) {
                menuItems.splice(index, 1);
                updated = true;
              }
            }
          }
        }
      });
    }
  }

  $: {
    if (menuItems.length <= maxVisibleItems) {
      visibleItems = menuItems;
      hiddenItems = [];
    } else {
      visibleItems = menuItems.slice(0, maxVisibleItems);
      hiddenItems = menuItems.slice(maxVisibleItems);
    }
  }

  $: if (visibleItems) {
    visibleItems = visibleItems.sort(
      (a, b) => parseFloat(a.weight) - parseFloat(b.weight),
    );
  }

  const fetchMenuList = () => {
    const headers = { Authorization: "Token " + getAuthKey() };

    axios
      .post(
        LEGISLATOR_MANAGEMENT_MODULE + "/menu/visible/list",
        { organisation_office_id: getPersonOrgOfficeId() },
        { headers },
      )
      .then((response) => {
        // Handle the response data
        if (response.data.statusCode === "S10001") {
          menuItems = response?.data?.rows;
          menuItemsLoading.set(false);
          menuItemsList.set(menuItems);
          if (menuItems.length > 0) {
            // startNewAiConversation.set({
            //   startNew: true,
            //   menu_id: menuItems[0].id,
            //   parent_menu_id: menuItems[0].parent_menu_id,
            //   menu_request_type: "intro",
            // });
            selectedMenuItem.set(menuItems[0]);
          } else {
            // startNewAiConversation.set({
            //   startNew: true,
            //   menu_id: null,
            //   parent_menu_id: null,
            //   menu_request_type: null,
            // });
            selectedMenuItem.set({ id: null });
          }
        }
        if (response.data.statusCode === "E100001")
          alert(response.data.message);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  };

  const moreMenu = {
    id: "more",
    title: "More",
    name: "More",
    icon: `<svg class="cleandesk-left-menu-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M240-400q-33 0-56.5-23.5T160-480q0-33 23.5-56.5T240-560q33 0 56.5 23.5T320-480q0 33-23.5 56.5T240-400Zm240 0q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm240 0q-33 0-56.5-23.5T640-480q0-33 23.5-56.5T720-560q33 0 56.5 23.5T800-480q0 33-23.5 56.5T720-400Z"/></svg>`,
  };
</script>

<div
  style="display: flex; justify-content: space-between; padding: 4px 16px; border-top: 0.5px solid #707070; background-color: #fff;"
>
  {#each visibleItems as menuItem}
    <MobileBottomMenu
      {menuItem}
      {expanded}
      isSelected={$selectedMenuItem?.id === menuItem?.id}
      on:menuItemClick={homeClicked}
      on:expandMenu={expandLeftMenu}
    />
  {/each}

  {#if hiddenItems.length > 0}
    <MobileBottomMenu
      menuItem={moreMenu}
      {expanded}
      on:menuItemClick={() => (moreExpanded = !moreExpanded)}
      on:expandMenu={expandLeftMenu}
    />
  {/if}
</div>

{#if moreExpanded}
  <div
    class="cleandesk-bottom-sheet"
    transition:fly={{ y: 500, duration: 400 }}
  >
    <div
      style="display: flex; flex-direction: column; height: calc(100% - 1em); padding: 1em 0; overflow: scroll;"
    >
      {#each menuItems as menuItem}
        <MenuButton
          {menuItem}
          expanded={true}
          isSelected={$selectedMenuItem?.id === menuItem?.id}
          selectedMenuItem={$selectedMenuItem}
          {expanMenuItem}
          on:menuItemClick={homeClicked}
          on:expandMenu={expandLeftMenu}
        />
      {/each}
    </div>
    <button
      on:click={() => (moreExpanded = false)}
      class="cleandesk-bottom-sheet-close">X</button
    >
  </div>
{/if}
